.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: 'move';
}

.item_image {
  padding: 0 16px;  
    pointer-events: none;
}

.item_description {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item_name {}