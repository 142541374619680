.modal {
  background: #1C1C21;
  border: 1px solid rgba(76, 76, 255, 0.2);
  box-sizing: border-box;
  border-radius: 40px;
  width: 720px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
}