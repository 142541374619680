.login {
  width: 100%;
  max-width: 480px;
  margin: calc(88px + 40px) auto 0;
}

.input_container {
  display: grid;
  row-gap: 24px;
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

.form {
  width: 100%;
  margin-bottom: 64px;
}

.button_container {
  width: fit-content;
  margin: 0 auto;
}

.link_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0;
}

.text {
  margin: 0;
  margin-right: 8px;
}

.link {
  color: #4c4cff;
  text-decoration: none;
  transition: all 0.2s;
}

.link:hover {
  opacity: 0.8;
}



.error_active {
  display: block;
}

:global(.input__container) {
  width: 100%;
}
:global(.input) {
  width: 100%;
}


