.wrapper {
  box-sizing: border-box;
}

.nav_panel {
  width: 100%;
  min-height: 88px;
  position: fixed;
  background: #1C1C21;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
.main {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  padding: 88px 20px 0;
}