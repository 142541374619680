
.header {
  margin-bottom: 40px;
  text-align: center;
}

.card_details_wrapper {
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.order_id {
  margin-bottom: 40px;
  text-align: center;
}

.orders_item {
  width: 100%;
  background-color: #1c1c21;
  border-radius: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  margin: 12px 0 60px;
}

.done {
  color: rgba(0, 204, 204, 1);
}

.ingredients_cost {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ingredients_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0 40px;
  min-height: 310px;
  max-height: 312px;
  overflow-y: scroll;
  scrollbar-width: thin;
    padding-right: 5px;
}

.ingredients_list::-webkit-scrollbar {
  width: 8px;
}
.ingredients_list::-webkit-scrollbar-track {
  background: #2f2f37;
}
.ingredients_list::-webkit-scrollbar-thumb {
  background: #8585ad;
}

.ingredient_wrapper {
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;
}

.img_wrapper {
  width: 68px;
  height: 68px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  background: linear-gradient(63.18deg, #801ab3 0%, #4c4cff 100%);
}

.img {
  width: 64px;
  height: 64px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  background: #131316;
}

.name {
  flex-grow: 1;
}

.ingredient_cost {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ingredients_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
