

.menu_link {
  display: flex;
  text-decoration: none;
  color: #8585AD;

}

.menu_link:hover {
  color: #F2F2F3;
}

.menu_link_active {
  color: #f2f2f3;
}
