.profile {
  
  width: 100%;
  max-width: 1240px;
  display: flex;
  margin: calc(120px + 88px) auto 0;
}

.nav_container {
  max-width: 320px;
  width: 100%;
}

.nav__text {
  margin: 80px 0 0;
  opacity: 0.4;
}

.form {
  margin-left: 60px;
  max-width: 480px;
  width: 100%;
  display: grid;
  row-gap: 24px;
  grid-template-rows: auto;
}

.button_container {
  width: fit-content;
  margin: 0 0 0 auto;
}

.error {
  margin: 0 0 2px 24px;
  color: #e52b1a;
  display: none;
}

.error_active {
  display: block;
}

